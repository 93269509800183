body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

div {
  display: flex;
  flex-direction: column;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5, p, div, span, html, body {
  padding: 0px;
  margin: 0px;
}

h1, h2, h3, h4, h5 {
  font-weight: 300;
}

input {
  padding: 8px;
}

button {
  padding: 8px 10px;
}

button:hover {
  cursor: pointer;
}

.loginBtnEnabled, .syncBtn {
  background-color: #3fa344 !important;
  color: #fff !important;
}

.loginBtnEnabled:hover, .syncBtn:hover {
  background-color: #44ad49 !important;
}

.logoutButton {
  margin-top: 10px !important;
}

.copyableToken {
  cursor: pointer;
}

.copyableToken:hover {
  background-color: #d6ffd8 !important;
}

.mainGrid > div {
  padding: 0 !important;
}

.mainGrid {
  margin: 0 1.5rem !important;
}

.remainOpenWarning {
  margin: 0;
}

.advancedExpander {
  padding: 8px;
  display: inline;
  margin-top: 15px;
  color: #adadad;
  cursor: pointer;
}
.advancedExpander:hover {
  color: #3fa344
}

.advancedSection {
  padding: 10px;
  background-color: #f0f0f0;
  text-align: left;
  border-radius: 5px;
  font-size: 1em;
  font-size: 1rem !important;
}

.advancedSection > textarea {
  font-size: 1rem !important;
  height: 80px !important;
  resize: none !important;
  border-radius: 5px !important;
}

.allowedIpList {
  display: inline-block;
}

.allowedIpInList {
  display: inline-block;
  border-radius: 5px;
  background-color: #3fa344;
  color: #fff;
  padding: 3px;
  margin-right: 5px;
  margin-top: 5px;
}

